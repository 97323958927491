body {
  background-color: #1b1c1d;
  color: rgba(255,255,255,.9);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.spacer {
  margin-top: 32px;
}

a {
  color: rgba(255,255,255,.9);
}
a:hover {
  color: rgba(255,255,255,.9);
}